import * as Sentry from "@sentry/react"
enum EnvironmentType {
  Development = "DEVELOPMENT",
  QA = "QA",
  Production = "PRODUCTION",
}

interface SentryConfig {
  environment: string
  tracePropagationTargets: string[]
}

const environmentConfigurations: Record<EnvironmentType, SentryConfig> = {
  [EnvironmentType.Development]: {
    environment: EnvironmentType.Development,
    tracePropagationTargets: ["localhost", "https://devvgtc.pongstudios.ca"],
  },
  [EnvironmentType.QA]: {
    environment: EnvironmentType.QA,

    tracePropagationTargets: ["localhost", "https://qavgtc.pongstudios.ca"],
  },
  [EnvironmentType.Production]: {
    environment: EnvironmentType.Production,
    tracePropagationTargets: ["localhost", "https://vgtcentral.com"],
  },
}

function getSentryConfig(): SentryConfig {
  const deploymentTarget = process.env.REACT_APP_SENTRY_ENVIRONMENT as EnvironmentType
  return environmentConfigurations[deploymentTarget] !== null
    ? environmentConfigurations[deploymentTarget]
    : environmentConfigurations[EnvironmentType.Development]
}

const config = getSentryConfig()

Sentry.init({
  release: "vgtCentral v" + String(process.env.REACT_APP_VERSION_NUMBER),
  dsn: process.env.REACT_APP_SENTRY_DEV_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: config.environment,
  // Performance Monitoring
  // The percentage of transactions to capture for performance monitoring (0.25 = 25% of transactions are tracked)
  tracesSampleRate: 0.25,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    process.env.REACT_APP_SENTRY_URL !== undefined
      ? process.env.REACT_APP_SENTRY_URL
      : "https://devvgtc.pongstudios.ca",
  ],
  // The percentage of sessions to capture for session replays. Useful for understanding user interactions and identifying UI issues
  replaysSessionSampleRate: 0,
  // Captures session replays for all sessions where an error occurred, helping in debugging issues
  replaysOnErrorSampleRate: 1.0,
})
